
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header{
	width:100%; 
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;	
    height: 70px;
    position:fixed;
    top:5%;left:0;right:0;
    padding: 0px 0;
    z-index: 120; 
    .wrapper {
    	&:before, &:after {position:absolute; content:''; width: 6px;height: 6px;border-radius: 3px;background: #c3c3c3;    top: 28px;    right: 97px;}
    	&:after {    right: 48px;    top: 78px;    visibility: visible;}
    }
}

.head-links {float: left;font-family:'ProximaNova';    padding: 12px 23px 0 16px;
	li {list-style: none; float: left; margin:0 60px 0 0;
		a {display: inline-block;vertical-align: top;line-height: 38px;color: #636363;font-size: 14px;text-transform: uppercase;font-weight: bold;position: relative;
			&:after {content:'';display: block;height: 3px;width: 11px;background: #e63a5e;    transition: all 0.2s linear;}
			&:hover:after {width: 100%;}
		}
		&.active a {color: #e63a5e;
			&:after {width: 100%;}
		}
	}
}
.head-side-link {  float: left;font-family:'ProximaNova'; right: 60px;    top: 104px;    font-size: 14px;text-transform: uppercase;font-weight: bold; transform: rotate(-90deg); transform-origin: right top; position: absolute;}
.head-phone {color: #2e2e2e;float: right;font-size: 24px;font-weight: 400;margin-top: 16px;position: relative;
	span {font-weight: 300;color: #8e8e8e;vertical-align: middle;    font-size: 56%;    display: inline-block;    margin: -14px -1px 0px 0;}

}
.logo {float: left; width: 190px; display: block;     margin-top: 4px;
	span {display: block;font-size: 10px;font-weight: 400;color: #58585a;text-shadow: 0 0;    margin-top: 12px;    letter-spacing: 1.3px;    word-spacing: 3.8px;    text-transform: uppercase;}
}  
.visual-links {    z-index: 40;border-radius: 50%;margin-left: -782px; transform: translate3d(0,0,0); width: 60px;height: 60px;text-align: center;position: absolute;left: 50%;margin-top: -40px; border:1px solid #d7d7d7;top: 50%;
	&:before {position:absolute; content:''; width: 6px;height: 6px;border-radius: 3px;background: #c3c3c3;top:50%;left: 50%;transform: translateX(-50%) translateY(-50%);}
	a:hover {color: #ec2047;}
}
.visual-links__photo, .visual-links__video {    font-size: 14px; width: 30px;line-height: 30px;left: 15px;top: -14px;position: absolute;color: #010101;}
.visual-links__video {top:auto;bottom:-16px;}
.close-slider {text-transform: uppercase; z-index: 50;font-family:'ProximaNova';font-weight: 400;    position: absolute;margin-left: -778px;height: 53px;width: 115px;    left: 50%;    margin-top: -38px;    top: 50%;font-size: 12px;color: #a0a0a0;
	i {margin-right: 7px;    margin-top: -2px; display: inline-block;position: relative;vertical-align: middle;width: 53px;height: 53px;
		&:before, &:after {backface-visibility: hidden; background:#ec2047; height:3px;border-radius: 2px; display:block;top: 25px;    left: -10px;width:73px; position:absolute;content:'';}
		&:before {transform: rotate(45deg);}
        &:after {transform: rotate(-45deg);}
    
	}
}

.mobile-button {position:relative; z-index:150; float:right; height: 54px; margin: 4px 0 0 47px; border-radius: 50%; background: rgba(255,255,255,0.55); width: 54px;box-sizing: content-box;text-align: center;
    span {backface-visibility: hidden;position: relative; transition:all 0.3s ease-out;background:#000;width:14px; height:3px;border-radius: 2px; display:inline-block;margin:26px 4px 0px auto;}
    span:before,  span:after{backface-visibility: hidden; transition:all 0.3s ease-out;content:''; background:#000; height:3px;border-radius: 2px; display:block;top:-6px;left:0;width:19px; position:absolute;}
    span:after {top:auto;bottom:-6px;width: 19px}
	&:hover {background: #fff;}
    &.active {background: #fff;
        span { background: transparent; }
        span:before {width:26px; top: 0px; left: -3px;  transform: rotate(45deg); -webkit-transform:rotate(45deg);      -moz-transform:rotate(45deg);       -ms-transform: rotate(45deg);         -o-transform: rotate(45deg);  }
        span:after {width:26px; bottom:0px; left: -3px;  transform: rotate(-5deg); -webkit-transform:  rotate(-45deg);      -moz-transform: rotate(-45deg);       -ms-transform:  rotate(-45deg);         -o-transform:  rotate(-45deg);   }
    }
}   

.menu-right { min-width: 412px;   margin-top: 128px;float: right;width: 32.5%; transition: all 0.3s linear; backface-visibility: hidden;	transform: translateX(100px); opacity: 0;}
.menu-left {    overflow: hidden;    padding-right: 11px;transition: all 0.3s linear; backface-visibility: hidden;transform: translateX(-100px); opacity: 0; position: relative;}
.menu {transform: translate3d(0,0,0); position: fixed;background: #e1e1e1;transform: translateY(-100%); top:-0%;left: 0;width: 100%;height: 100%;
	z-index: 100;opacity: 0;transition: all 0.3s linear;  transition-delay: 0.3s;
	.v-align {position: absolute;left: 0;right: 0;top: 50%;transform: translateY(-50%);backface-visibility: hidden;}
	.wrapper { position: relative;
		&:before, &:after {position: static;height: 0;width: 100%;background: transparent;display: table;}
	}
}
.nav {font-family:'ProximaNova';
	>ul {list-style: none; 
		>li {    margin-bottom: 25px;
			a {display: inline-block;vertical-align: top;line-height: 38px;color: #3c3c3c;font-size: 18px; font-weight: 400;position: relative;
				&:after {content:'';display: block;height: 5px;width: 23px;background: #ff3766;    transition: all 0.2s linear;}
				&:hover{font-weight: bold;
					&:after {width: 100%;}
				}
			}
			&.active a {font-weight: bold;
				&:after {width: 100%;}
			}
		}
	}
}
.hid .menu {opacity: 1;transform: translateY(0%);
	.menu-right { transform: translateX(0px); transition-delay: 0.5s;opacity: 1;}
	.menu-left { transform: translateX(0px); transition-delay: 0.5s;opacity: 1;}
}
.menu-hexs {text-align: center; max-width: 850px; margin: 0 auto;
	.hex {display: inline-block;vertical-align: top; width: 105px;margin: 0 28px;}
	.hex:first-child {display: block;margin: 0 auto 8px;}
	.hex:nth-child(2), .hex:nth-child(10) {margin-left: 100px;}
	.hex:nth-child(5), .hex:nth-child(13) {margin-right: 100px;}
	.hex:nth-child(3), .hex:nth-child(4)  {    margin: 6px 13px 0px;}
	.hex:nth-child(11), .hex:nth-child(12)  {    margin: -8px 13px 17px;}
	.hex:nth-child(7) { margin:0 83px 9px 50px;}
	.hex:nth-child(8) { margin:0 50px 9px 83px;}
	.hex:last-child {display: block;margin: 0 auto;}
}
.menu-tel {font-size: 20px;color: #2e2e2e; position: absolute;     top: 8px;    left: 254px;font-weight: 300;font-family:'ProximaNova';}
.menu-lang {position: absolute; left: 256px;    top: 54px;
	a {display: inline-block;vertical-align: middle;text-transform: uppercase;font-weight: bold;margin:0 14px 0 0;font-family:'ProximaNova';font-size: 14px;font-weight: 300;color: #2e2e2e; 
		&:hover, &.active {color:#e63a5a;}
		&.active {font-weight: 600;}
	}
}
.menu-soc {position: absolute; left: 256px;bottom: 30px;
	a {font-size: 14px;color: #7f7f7f;font-weight: 300;font-family:'ProximaNova';text-transform: uppercase;    display: inline-block;    vertical-align: top;    line-height: 28px;
		&:hover {color:#e63a5a;}
	}
}

/*-------------------------------------------------*/
@media screen and (max-width: 1599px) {
	.visual-links {margin-left: 0;left: 7px;    margin-top: -50px;}
	.close-slider {margin-left: 0;left: 11px;   margin-top: -48px;}
}  
@media screen and (max-height: 649px) {
	.menu-hexs { max-width: 700px; 
		.hex {    width: 91px;    margin: 0 21px;}
		.hex:nth-child(3), .hex:nth-child(4) {    margin: 5px 10.5px 1px;}
		.hex:nth-child(7) {    margin: 0 72px 1px 45px;}
		.hex:nth-child(8) {    margin: 0 45px 8px 72px;}
		.hex:nth-child(11), .hex:nth-child(12) {    margin: -7px 11px 14px;}
	}
	.menu-right {    margin-top: 85px;}
}
@media screen and (max-width: 1367px) {
	header {    top: 5.9%;
		.wrapper {    padding: 0 8px;
			&:before {right: 81px;}
			&:after {    right: 32px;}
		}
	}
	.head-links {    padding: 12px 21px 0 21px;
		li {    margin: 0 29px 0 0;}
	}
	.head-side-link {    right: 44px;}
	.menu-hexs {padding-top: 7px;    max-width: 700px;margin: 0;
		.hex {    width: 91px;    margin: 0 21px;}
		.hex:nth-child(3), .hex:nth-child(4) {    margin: 5px 10.5px 1px;}
		.hex:nth-child(7) {    margin: 0 72px 1px 45px;}
		.hex:nth-child(8) {    margin: 0 45px 8px 72px;}
		.hex:nth-child(11), .hex:nth-child(12) {    margin: -7px 11px 14px;}
	}
	.menu-right {    margin-top: 85px;}
	.menu-tel {    left: 223px;}
	.menu-lang, .menu-soc {    left: 225px;}
	.menu-left {    padding-right: 0px;}


}
@media screen and (max-height: 569px) {
	.menu-hexs { max-width: 700px; 
		.hex {    width: 80px;    margin: 0 21px;}
		.hex:nth-child(3), .hex:nth-child(4) {    margin: 5px 10.5px 1px;}
		.hex:nth-child(7) {    margin: 0 72px 1px 45px;}
		.hex:nth-child(8) {    margin: 0 45px 8px 72px;}
		.hex:nth-child(11), .hex:nth-child(12) {    margin: -7px 11px 14px;}
	}
	.menu-right {    margin-top: 45px;}
}
@media screen and (max-height: 499px) {
	.menu-hexs { max-width: 570px; 
		.hex {    width: 70px;    margin: 0 15px;}
		.hex:nth-child(3), .hex:nth-child(4) {    margin: 3px 7px 0px;}
		.hex:nth-child(7) {    margin: 0 62px 1px 40px;}
		.hex:nth-child(8) {    margin: 0 40px 8px 62px;}
		.hex:nth-child(11), .hex:nth-child(12) {    margin: -7px 7px 3px;}
	}
	.menu-right {    margin-top: 0px;}   
}
@media screen and (max-width: 1199px) {
	header { 
		.wrapper {    padding: 0 20px;
			&:before {right: 94px;}
			&:after { right: 45px;}
		}
	}
	.logo {z-index: 20;    position: absolute;    left: 42px;    top: 0;}
	.head-links {    padding: 12px 21px 0 262px;}
	.head-side-link {    right: 57px;}
	.visual-links {    left: 18px;}
	.menu-left {display: none;}
	.menu-right {margin: 0; float: none;width: 100%;height: 100%; min-width: 250px; }
	.nav {    position: absolute;    left: 21px;    margin-top: -50px;    right: 0;    top: 50%; 
		> ul > li {margin: 0 25px 0 0;display: inline-block;vertical-align: top;}
	}
	.menu {
		.wrapper {height: 100%;}
		.v-align {height: 100%;}
	}
	.menu-tel { top: 8.4%;    left: 20px;}
	.menu-lang {top: 14.2%;   left: 22px;}
	.menu-soc { left: 22px;   bottom: 7.5%;}
	.close-slider {left: 22px;}

}

@media screen and (min-width: 1024px){
	
}

@media screen and (max-width: 1023px) {
	header {     top: 3.2%;
		.wrapper {     padding: 0 13px;
			&:before {right:87px;}
			&:after {display: none;}
		}
	}
	.head-links { display: none;}
	.head-side-link {display: none;}
	.visual-links {display: none;}
	.logo {    left: 17px;}
	.head-phone:after {content: '';position: absolute;right: 0;bottom: -3px;border-bottom: 4px dotted #ff3766;    width: 95px;}
	
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	
}

@media screen and (max-width: 749px) {
	header {    top: 25px;}
	.menu-tel {    top: 5.4%;    left: 0px;}
	.menu-soc {    left: 0px;    bottom: 4%;}
	.menu-lang {   top: auto;    left: auto; right: 0px;    margin-bottom: 62px;bottom: 4%;
		a {margin: 0 0px 0 17px;}
	}
	.nav { transform: translateY(-50%);    left: 20px;    margin-top: -20px;
		> ul > li {display: block;margin: 0 0 15px;
			a {line-height: 28px;}
		}
	}
	.long header {position: relative;padding-top:25px; top:0; height:95px;}
	.close-slider {top: 13px;right:9px;left: auto;margin-top: 0; font-size: 0;width: 53px;}

}
@media screen and (max-width: 499px) {
	header .wrapper{    padding: 0 10px;
		&:before {display: none;}
	}
	.head-phone {display: none;}
	.logo {    left: 9px;}
}
@media screen and (max-width: 374px) {
	
}
@media screen and (max-height: 599px){
	header {    top: 5px;}
}

@media screen and (max-height: 469px) and (max-width: 1199px) {   
	.menu-right {overflow: auto;padding: 23px 0;}
	.menu-tel {position: static;}
	.menu-soc {position: static;float: left;}
	.menu-lang {float: right;    margin: 4px 13px 0;    position: static;}   
	.nav {position: static; transform: translateY(0%); margin: 24px 0 24px 20px;}  
	.menu .wrapper {padding-right: 0;}

}    
