@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Thin.eot?ver1.0');
    src: url('../fonts/Roboto Thin.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto Thin.woff2?ver1.0') format('woff2'),
        url('../fonts/Roboto Thin.woff?ver1.0') format('woff'),
        url('../fonts/Roboto Thin.svg#Roboto Thin?ver1.0') format('svg');
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}
@font-face {
    font-family:'Roboto Thin';
    src: url('../fonts/Roboto Thin Italic.eot?ver1.0');
  src: url('../fonts/Roboto Thin Italic.eot?#iefix?ver1.0') format('embedded-opentype'),
    url('../fonts/Roboto Thin Italic.woff2?ver1.0') format('woff2'),
    url('../fonts/Roboto Thin Italic.woff?ver1.0') format('woff'),
    url('../fonts/Roboto Thin Italic.svg#Roboto Thin Italic?ver1.0') format('svg');
    font-weight: 250;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}
@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Light.eot?ver1.0');
    src: url('../fonts/Roboto Light.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto Light.woff?ver1.0') format('woff'),
        url('../fonts/Roboto Light.ttf?ver1.0') format('truetype'),
        url('../fonts/Roboto Light.svg#Roboto?ver1.0') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-2044;
}
@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto.eot?ver1.0');
    src: url('../fonts/Roboto.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto.woff?ver1.0') format('woff'),
        url('../fonts/Roboto.ttf?ver1.0') format('truetype'),
        url('../fonts/Roboto.svg#Roboto?ver1.0') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-2044;
}

@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Medium.eot?ver1.0');
    src: url('../fonts/Roboto Medium.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto Medium.woff?ver1.0') format('woff'),
        url('../fonts/Roboto Medium.ttf?ver1.0') format('truetype'),
        url('../fonts/Roboto Medium.svg#Roboto-Medium?ver1.0') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-2044;
}

@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Bold.eot?ver1.0');
    src: url('../fonts/Roboto Bold.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto Bold.woff?ver1.0') format('woff'),
        url('../fonts/Roboto Bold.ttf?ver1.0') format('truetype'),
        url('../fonts/Roboto Bold.svg#Roboto-Bold?ver1.0') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-2044;
}

@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Black.eot?ver1.0');
    src: url('../fonts/Roboto Black.eot?#iefix?ver1.0') format('embedded-opentype'),
        url('../fonts/Roboto Black.woff2?ver1.0') format('woff2'),
        url('../fonts/Roboto Black.woff?ver1.0') format('woff'),
        url('../fonts/Roboto Black.svg#Roboto Black?ver1.0') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}


@font-face {
	font-family:'ProximaNova';
	src: url('../fonts/ProximaNova-Light.eot');
	src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Light.woff') format('woff'),
		url('../fonts/ProximaNova-Light.ttf') format('truetype'),
		url('../fonts/ProximaNova-Light.svg#ProximaNova') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2666;
}
@font-face { 
	font-family: "ProximaNova"; 
	src: url("../fonts/ProximaNovaRegular.eot"); 
	src: url("../fonts/ProximaNovaRegular.eot?#iefix") format("embedded-opentype"), 
	url("../fonts/ProximaNovaRegular.woff") format("woff"), 
	url("../fonts/ProximaNovaRegular.ttf") format("truetype"); 
	font-weight: 400; 
	font-style: normal; 
}
@font-face {
	font-family:'ProximaNova';
	src: url('../fonts/ProximaNova-Semibold.eot');
	src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Semibold.woff') format('woff'),
		url('../fonts/ProximaNova-Semibold.ttf') format('truetype'),
		url('../fonts/ProximaNova-Semibold.svg#ProximaNova') format('svg');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2666;
}
@font-face{
	font-family:'ProximaNova';
	src:url('../fonts/ProximaNova-Bold.eot?ver1.0');
	src:url('../fonts/ProximaNova-Bold.eot#iefix?ver1.0') format('embedded-opentype'), 
	url('../fonts/ProximaNova-Bold.woff?ver1.0') format('woff'), 
	url('../fonts/ProximaNova-Bold.ttf?ver1.0') format('truetype'), 
	url('../fonts/ProximaNova-Bold.svg?ver1.0') format('svg');
	font-weight:bold;font-style:normal;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/proxima_nova_extrabold-webfont.eot');
    src: url('../fonts/proxima_nova_extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima_nova_extrabold-webfont.woff') format('woff'),
         url('../fonts/proxima_nova_extrabold-webfont.ttf') format('truetype'),
         url('../fonts/proxima_nova_extrabold-webfont.svg#proxima_nova_thextrabold') format('svg');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/proxima_nova_black-webfont.eot');
    src: url('../fonts/proxima_nova_black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima_nova_black-webfont.woff') format('woff'),
         url('../fonts/proxima_nova_black-webfont.ttf') format('truetype'),
         url('../fonts/proxima_nova_black-webfont.svg#proxima_nova_blblack') format('svg');
    font-weight: 900;
    font-style: normal;
}




