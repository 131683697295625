/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;position: relative;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow{background:transparent; border:none;position:absolute; top:50%; margin-top:-12px;z-index:20;cursor:pointer; font-size:0;width:60px;height:60px;z-index: 10;box-sizing:border-box;-webkit-transition:all 0s ease-out;-moz-transition:all 0s ease-out;transition:all 0s ease-out;}
.slick-arrow:before {margin-left: 10px;width: 32px;height: 32px;display: inline-block;vertical-align: middle; border-top: 15px solid #000;border-left: 15px solid #000;box-sizing: border-box; transform: rotate(-45deg);content:''; -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg);}
.slick-arrow:hover:before {border-color: #e63a7b;}
.slick-prev{ }
.slick-next{transform: rotate(180deg); -webkit-transform:  rotate(180deg);-moz-transform: rotate(180deg);-ms-transform:  rotate(180deg); -o-transform:  rotate(180deg);}

.slick-dots { text-align:center;   position: absolute;    left: 0px; right:0; bottom: 0px;line-height:0;    margin: 0;}
.slick-dots li {    list-style: none;    display: inline-block;    margin: 0 27px 0 0px;}
.slick-dots li button {padding:0; box-sizing:border-box;    display: block;    width: 11px;cursor: pointer;    height: 12px;    border: none;    background: url(../img/dots.png) no-repeat left 0;  font-size: 0;}
.slick-dots li.slick-active button, .slick-dots li button:hover {background-position: right 0; height: 22px;}
