
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer{

	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;	
        padding-bottom: 2px;
    position: absolute;    bottom: 7%;left: 0;right: 0;z-index: 20;
}
.soc {position: absolute;    right: 37px;bottom: -2px;width: 30px;
	a {font-size: 14px;display: inline-block;vertical-align: middle;color: #000;width: 30px;line-height: 30px;text-align: center;    margin: 15px 0 1px;
		&:hover {color:#e63a7b;}
	}
	.fa-star {font-size: 19px;  margin: 1px -8px 0px 0;    vertical-align: middle;}
}
.lang {float: left;    margin: -1px 11px 0px 31px;    width: 15%;font-family:'ProximaNova';
	a {font-weight: bold;text-transform: uppercase;font-size: 14px;transform: rotate(-90deg); transform-origin: left bottom;display: inline-block;
		&:hover {}
		&.active {display: none;}
	}
}
.foot-addr {float: left;font-size: 14px;color: #2e2e2e;font-weight: bold;float: left;font-family:'ProximaNova';
	.fa {display: inline-block;vertical-align: middle;    margin: 0 8px -1px 0;    padding-bottom: 8px;    font-size: 18px;    text-align: center;    width: 11px; border-bottom: 3px solid #e63a5e;color: #e63a7b;}
}

/*---------------------*/
@media screen and (max-width: 1367px) {
	footer {padding-bottom: 1px;}
	.soc {    right: 24px;}
	.lang {    margin: -1px 4px 0px 20px;    width: 7%;}
}
@media screen and (max-width: 1199px) {
	.soc {    right: 32px;}
	.lang {    margin: -1px 7px 0px 32px;    width: 6%;}
}

@media screen and (min-width: 1024px){
	
}

@media screen and (max-width: 1023px) {
	.soc {    right: 6px;}
	.lang {    margin: -1px 7px 0px 8px;}
	.soc .fa-star {    margin: 12px 1px 0px 0;}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	
}

@media screen and (max-width: 749px) {
	.soc, .lang {display: none;}
	.foot-addr {text-align: center;float: none;
		.fa {display: none;}
	}
	footer { bottom: 42px;
		.wrapper {padding: 0 10px;}
	}
	.long footer {position: relative;margin-bottom: 42px;bottom:0;}
	
}
@media screen and (max-width: 639px) {
	

}
@media screen and (max-width: 374px) {
	
}

@media screen and (max-height: 599px) {
	footer {    bottom: 15px;}

}