/*
 *	generated by WOW Slider 2.5
 *	template Quiet
 */
#wowslider-container { 
	zoom: 1; 
	position: relative; 
	width: 100%;
	max-width:100%;
	max-height:100%;
	height:100%;
	margin:0px auto 0px;
	z-index:100;
	border:none;
	text-align:left; /* reset align=center */
	font-size: 10px;
}
#wowslider-container.fullscreen-mode,
#wowslider-container.fullwidth-mode {
	max-width:none;
	max-height:none;
}


* html #wowslider-container{ width:100% }
#wowslider-container .ws_images ul{
	position:relative;
	width: 10000%; 
	height:100%;
	left:0;
	list-style:none;
	margin:0;
	padding:0;
	border-spacing:0;
	overflow: visible;
	/*table-layout:fixed;*/
}
#wowslider-container .ws_images ul li{
	position: relative;
	width:1%;
	height:100%;
	line-height:0; /*opera*/
	overflow: hidden;
	float:left;
	font-size:0;
	padding:0 0 0 0 !important;
	margin:0 0 0 0 !important;
}

#wowslider-container .ws_images{
	position: relative;
	left:0;
	top:0;
	width:100%;
	height:100%;
	max-height:100%;
	overflow:hidden;
}
#wowslider-container.fullscreen-mode .ws_images,
#wowslider-container.fullwidth-mode .ws_images {
	max-height:none;
}
#wowslider-container .ws_images a{
	width:100%;
	display:block;
	color:transparent;
}
#wowslider-container.fullscreen-mode .ws_images a,
#wowslider-container.fullwidth-mode .ws_images a {
	height:100%;
}
#wowslider-container img{
	max-width: none !important;
}
#wowslider-container .ws_images img{
	width:100%;
	border:none 0;
	max-width: none;
	padding:0;
	margin:0;
}

#wowslider-container .ws_images iframe {
	position: absolute;
	z-index: -1;
}
#wowslider-container a{ 
	text-decoration: none; 
	outline: none; 
	border: none; 
}
#wowslider-container  .ws_bullets div{
	position:relative;
	float:left;
	font-size: 0px; 
}
#wowslider-container  a.wsl{
	display:none;
}
#wowslider-container  .ws_bullets { }
#wowslider-container .ws_bullets a {    color: #a9a9a9;cursor: default; 	position:relative;display: none;}
#wowslider-container .ws_bullets a.ws_selbull{	display: block;}

#wowslider-container a.ws_next, #wowslider-container a.ws_prev {
	position:absolute;
	display:block;
	text-shadow: none;
	text-align: center;
	top:50%;
	margin-top:-63px;
	font-size: 0;line-height: 0;
	height: 100px;
	width: 60px;
	z-index:60;
	right:50%;
	line-height: 100px;  
	&:before {margin-left: 10px;width: 32px;height: 32px;display: inline-block;vertical-align: middle; border-top: 15px solid #ffc000;border-left: 15px solid #ffc000;box-sizing: border-box; transform: rotate(-45deg);content:''; -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg);}
}
#wowslider-container a.ws_prev:before {width: 54px;height: 54px;    margin-left: 16px;}
#wowslider-container a.ws_next:hover:before,
#wowslider-container a.ws_prev:hover:before{
	border-color: #e63a7b;
}
#wowslider-container a.ws_next{
	margin-right:-778px;transform: rotate(180deg);
}

#wowslider-container a.ws_prev {	margin-right:-676px;}

* html #wowslider-container a.ws_next,* html #wowslider-container a.ws_prev{display:block}
#wowslider-container:hover a.ws_next, #wowslider-container:hover a.ws_prev {display:block}
/* bottom center */
#wowslider-container .ws_bullets {position: absolute;z-index: 70; top:50%;right:50%; margin-top: -41px;line-height: 60px;margin-right: -733px; text-align: center;width: 70px;font-size: 36px;color: #a9a9a9;font-weight: 800;font-family: "ProximaNova";

}
#wowslider-container  .ws_bullets div{
	left:-70%;
}

#wowslider-container .ws-title{
	position:absolute;
	display:block;
	bottom: 0.31em;
	left: 1.23em;
	margin: 9px;
	z-index: 50;
	font-size: 5.5em;
	color: #FFF;
	text-shadow: 1px 1px 1px #333333;
}
#wowslider-container .ws-title div,#wowslider-container .ws-title span{
	display:inline-block;
	padding:10px;
	background:#3398B6;
	border-radius:5px;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	opacity:0.95;
	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=95);	
}
#wowslider-container .ws-title div{
	display:block;
	background:#B02D15;
	margin-top:10px;
	color: #FFFFFF;
	opacity:0.8;
	text-shadow: 1px 1px 1px #000000;
}


#wowslider-container .ws_images > ul{
	animation: wsBasic 16.8s infinite;
	-moz-animation: wsBasic 16.8s infinite;
	-webkit-animation: wsBasic 16.8s infinite;
}
@keyframes wsBasic{0%{left:-0%} 14.88%{left:-0%} 25%{left:-100%} 39.88%{left:-100%} 50%{left:-200%} 64.88%{left:-200%} 75%{left:-300%} 89.88%{left:-300%} }
@-moz-keyframes wsBasic{0%{left:-0%} 14.88%{left:-0%} 25%{left:-100%} 39.88%{left:-100%} 50%{left:-200%} 64.88%{left:-200%} 75%{left:-300%} 89.88%{left:-300%} }
@-webkit-keyframes wsBasic{0%{left:-0%} 14.88%{left:-0%} 25%{left:-100%} 39.88%{left:-100%} 50%{left:-200%} 64.88%{left:-200%} 75%{left:-300%} 89.88%{left:-300%} }



#wowslider-container .ws_bullets  a img{
	text-indent:0;
	display:block;
	bottom:16px;
	left:-115px;
	visibility:hidden;
	position:absolute;
    -moz-box-shadow: 0 0 5px #999999;
    box-shadow: 0 0 5px #999999;
    border: 3px solid #ffffff;
	max-width:none;
}
#wowslider-container .ws_bullets a:hover img{
	visibility:visible;
}

#wowslider-container .ws_bulframe div div{
	height:75px;
	overflow:visible;
	position:relative;
}
#wowslider-container .ws_bulframe div div img {
	height: 100%;
}
#wowslider-container .ws_bulframe div {
	left:0;
	overflow:hidden;
	position:relative;
	width:200px;
	background-color:#ffffff;
}
#wowslider-container  .ws_bullets .ws_bulframe{
	display:none;
	bottom:18px;
	overflow:visible;
	position:absolute;
	cursor:pointer;
    -moz-box-shadow: 0 0 5px #999999;
    box-shadow: 0 0 5px #999999;
    border: 3px solid #ffffff;
}
#wowslider-container .ws_bulframe span{
	display:block;
	position:absolute;
	bottom:-9px;
	margin-left:0px;
	left:95px;
	background:url(./triangle.png);
	width:15px;
	height:6px;
}

#wowslider-container.ws_gestures,
#wowslider-container.ws_gestures > div > a {
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAABwSURBVEjH7ZJBEsAgCAMT/v/n9NCOSqe2oD2yNx1JggB4BCEFWyFASP2KMQE7ywWhe/tTRGCGogLk02tFctiW/SUgaMyQG4PdPzDn31rQbMb8FiAXgvsEJNax1yVlVGAjA93apP3HFhZTGIqiKH7iADB6HxPlHdNVAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE0LTA3LTA3VDEzOjQ5OjEwKzAyOjAwm7WiFAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNC0wNy0wN1QxMzo0OToxMCswMjowMOroGqgAAAAASUVORK5CYII="), move;
}
#wowslider-container.ws_gestures.ws_grabbing,
#wowslider-container.ws_gestures.ws_grabbing > div > a {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAABaSURBVEjH7ZMxCkAhDEOT8u9/5TpJ+xWkFse8IYutJgEB8RCHL1qCc90BEFnT6QH7mwgFHBUf8wJyS1TDLuc3vmighx37LZdIth3E5hKj9n6O0HRh+oJCiFcMxRUUDxR1CTMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTQtMDctMDdUMTM6NDk6MzgrMDI6MDDqf+sOAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE0LTA3LTA3VDEzOjQ5OjM4KzAyOjAwmyJTsgAAAABJRU5ErkJggg=="), move;
}

/* hide controls when video start play */
#wowslider-container.ws_video_playing .ws_bullets {
	
}
#wowslider-container.ws_video_playing:hover .ws_bullets {
	display: block;
}
/* IE fix because it don't show controls on hover if frame visible */
#wowslider-container.ws_video_playing_ie .ws_bullets,
#wowslider-container.ws_video_playing_ie a.ws_next,
#wowslider-container.ws_video_playing_ie a.ws_prev {
	display: block;
}


/* youtube/vimeo buttons */

#wowslider-container .ws_video_btn {
	position: absolute;
	display: none;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 55;
}
#wowslider-container .ws_video_btn.ws_youtube,
#wowslider-container .ws_video_btn.ws_vimeo {
	display: block;
}
#wowslider-container .ws_video_btn div {
	position: absolute;
	background-image: url(../img/playvideo.png);
	background-size: 100%;
	top: 50%;
	left: 50%;
	width: 54px;
	height: 80px;
	margin-left: -27px;
	margin-top: -40px;
}

#wowslider-container .ws_video_btn.ws_vimeo div {
	background-position: 0 100%;
}
#wowslider-container .ws_video_btn.ws_vimeo:hover div {
	background-position: 100% 100%;
}

#wowslider-container1 .ws_playpause.ws_hide {
	display: none !important;
}

#wowslider-container .ws_fullscreen {
	display: none;
	position: absolute;
	font-family: "fullscreen";
	top: .5em;
	right: .5em;
	font-size: 2.25em;
	z-index: 59;
	color: #1EA5C2;
	text-shadow: none;
}
#wowslider-container .ws_fullscreen:hover {
	-webkit-animation: pulseOut .9s infinite linear;
	-moz-animation: pulseOut .9s infinite linear;
	animation: pulseOut .9s infinite linear;
}


#wowslider-container:hover .ws_fullscreen {
	display: block;
}
#wowslider-container .ws_fullscreen:after {
	content: '\e800';
}

.ws_fs_wrapper {
	width:100%;
	height: 100%;
	background: #EFECDD;
}

.ws_fs_wrapper > #wowslider-container,
.ws_fs_wrapper > #wowslider-container .ws_images {
	margin:0 auto;
	max-height: none;
}

.ws_fs_wrapper > #wowslider-container .ws_fullscreen {
	position: fixed;
	display: block;
}

.ws_fs_wrapper > #wowslider-container .ws_fullscreen:after {
	content: '\e801';
}

.ws_fs_wrapper > #wowslider-container {
	border: 0;
	outline: 0;
	-webkit-box-shadow: 0;
	-moz-box-shadow: 0;
	box-shadow: 0; 
}



@-webkit-keyframes pulseIn {
    0% {-webkit-transform: scale(1);}
    50% {-webkit-transform: scale(1.2);}
    100% {-webkit-transform: scale(1);}
}
@-moz-keyframes pulseIn {
    0% {-moz-transform: scale(1);}
    50% {-moz-transform: scale(1.2);}
    100% {-moz-transform: scale(1);}
}
@keyframes pulseIn {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}

@-webkit-keyframes pulseOut {
    0% {-webkit-transform: scale(1);}
    50% {-webkit-transform: scale(0.8);}
    100% {-webkit-transform: scale(1);}
}
@-moz-keyframes pulseOut {
    0% {-moz-transform: scale(1);}
    50% {-moz-transform: scale(0.8);}
    100% {-moz-transform: scale(1);}
}
@keyframes pulseOut {
    0% {transform: scale(1);}
    50% {transform: scale(0.8);}
    100% {transform: scale(1);}
}





@-webkit-keyframes nextArrow {
	0%, 100% { -webkit-transform: translateX(0px); }
	25% { -webkit-transform: translateX(2px); }
	75% { -webkit-transform: translateX(-2px); }
}
@-moz-keyframes nextArrow {
	0%, 100% { -moz-transform: translateX(0px); }
	25% { -moz-transform: translateX(2px); }
	75% { -moz-transform: translateX(-2px); }
}
@keyframes nextArrow {
	0%, 100% { -moz-transform: translateX(0px);transform: translateX(0px); }
	25% { -moz-transform: translateX(2px);transform: translateX(2px); }
	75% { -moz-transform: translateX(-2px);transform: translateX(-2px); }
}

@-webkit-keyframes prevArrow {
	0%, 100% { -webkit-transform: translateX(0px); }
	25% { -webkit-transform: translateX(-2px); }
	75% { -webkit-transform: translateX(2px); }
}
@-moz-keyframes prevArrow {
	0%, 100% { -moz-transform: translateX(0px); }
	25% { -moz-transform: translateX(-2px); }
	75% { -moz-transform: translateX(2px); }
}
@keyframes prevArrow {
	0%, 100% { -moz-transform: translateX(0px);transform: translateX(0px); }
	25% { -moz-transform: translateX(-2px);transform: translateX(-2px); }
	75% { -moz-transform: translateX(2px);transform: translateX(2px); }
}
#wowslider-container  .ws_thumbs { 
    left: 0;right: 0;bottom:7%;position: absolute;z-index: 120;text-align: center;
	width:100%;
	
}
#wowslider-container  .ws_thumbs > div {margin: 0 auto;max-width: 900px; padding-bottom: 10px;padding-top:10px;}
#wowslider-container  .ws_thumbs .hex{
	width: 75px;display: inline-block;vertical-align: top;	margin: -8px 16px 0px;  
}

#wowslider-container .ws_thumbs .ws_selthumb.hex{
	.hex-in:after {content:'';background: #ec2b50;position: absolute;z-index: 3;opacity: 0.5;    width: 100%;    top: 0;    left: 0;    position: absolute;    height: 100%;}

}

#wowslider-container .ws_thumbs  a img{
	text-indent:0;
	max-width:none;
}

#wowslider-container .ws_images ul li { background-position:center; background-size:cover;
	img {opacity: 0;visibility:hidden;width:0;}
}

/* */
@media screen and (max-width: 1599px) {
	#wowslider-container a.ws_next { margin-right: 0; right: 31px;    margin-top: -72px;}
	#wowslider-container a.ws_prev { margin-right: 0; right: 134px;    margin-top: -72px;}
	#wowslider-container .ws_bullets {    right: 76px;    margin-right: 0;    margin-top: -51px;}
}

@media screen and (min-width: 1024px) {
	#wowslider-container .ws_thumbs > div{  transform: translate3d(0px, 0px, 0px) !important;}  
}
@media screen and (max-width: 1023px) {
	#wowslider-container .ws_thumbs {  bottom: 2.2%;}
	#wowslider-container .ws_thumbs > div {max-width: inherit;position: relative;    white-space: nowrap;    letter-spacing: -4px;    height: 100%;   /* width: 180em;*/    float: left;}
	#wowslider-container .ws_thumbs .hex {    margin: -8px 17px 0px;}
	#wowslider-container a.ws_next {    right: -5px;}
	#wowslider-container a.ws_prev {    right: 96px;}
	#wowslider-container .ws_bullets {    right: 41px;}

}
@media screen and (max-width: 749px) {
	#wowslider-container .ws_thumbs { margin-left:-18px; margin-right:-18px; width:auto; bottom: 3.3%;}   
	#wowslider-container .ws_thumbs .hex {width: 56px;    margin: -8px 26px 0px 0;}
	#wowslider-container a.ws_next {right: -12px;    margin-top: -62px;}
	#wowslider-container a.ws_prev {right: auto;left:-12px;    margin-top: -62px;
		&:before {    width: 32px;    height: 32px;    margin-left: 10px;}
	}
	#wowslider-container .ws_bullets {display: none;}
}