/* reset */
$min-width: 320px; 
$max-width: 1600px; 
$base-bg: #ebebeb; 
$base-font-size: 16px; 
$base-line-height: 18px; 
$base-font-family: 'Roboto', sans-serif; 
$custom-font-family: 'GothamPro', sans-serif; 
$link-color: #e63a7b;
$default-transition:all 0.2s linear;

/* main variables */

//text, titles
$text-color: #585858;
$title-color: #2e2e2e;
$title-font-family: $custom-font-family;

$font-size-h1: 50px;
$line-height-h1: 1.2;
$font-size-h2: 40px;
$line-height-h2:1.2;
$font-size-h3: 24px;
$line-height-h3: 1.2;