
//main styles

.main-wrapper{
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}
.wrapper{width: 100%;
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 23px 0 23px;
	margin: 0 auto;
	position: relative;
}

/* titles */
p{	
	/*@include font($base-font-size,$base-line-height,$text-color); font-weight: 400;*/
	padding: 15px 0 15px 0;
}
h1{	
	@include font($font-size-h1,$line-height-h1,$title-color);
	font-family: $title-font-family;
	padding: 15px 0 15px 0;
	font-weight: 900;	
	text-align:center;
	text-transform: uppercase;
}
h2{
	@include font($font-size-h2,$line-height-h2,$title-color);
	font-family: $title-font-family;
	padding: 15px 0 15px 0;
	font-weight: 900;	
	text-align:center;
	text-transform: uppercase;
	color: inherit;
}
h3{
	@include font($font-size-h3,$line-height-h3,$title-color);
	font-family: $base-font-family;
	padding: 15px 0 15px 0;
	font-weight: bold;	
	text-align:center;
	text-transform: uppercase;
	color: inherit;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{position:fixed;width:100%;height:100%; top:0;left:0;display:none;    background-color: #ebebeb;z-index: 200;
	&:after {content:''; background:url(../img/loader.gif?ver1.0) no-repeat left top;width:40px;height:40px;position:absolute;left:50%;top:50%;margin-left:-20px;margin-left:-20px;}
}
.loaded .icon-load{display:block;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content{	position: relative;z-index: 1;
	min-width: $min-width;
	text-align:left;
    width: 100%;
    height: 100%;
	order: 2;
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;
    padding-top:0px;  
    section {height: 100%;   }
    .flex { position: relative;
    	 -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; 
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;       
        -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
        -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
 -ms-flex-direction: column; 
        height: 100%;     
        float: left;
            width: 100%;
    }
    .wrapper {z-index: 5;-webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
   /* -webkit-flex: 0 1 100%;   
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;*/
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;}
}

/* */   
.btn{ font-family: "ProximaNova";margin: 0 16px; display:inline-block;border:none;overflow: visible; position: relative;font-weight:bold; text-align: center;font-size:18px; line-height:57px;height:57px;padding:0 20px; 
text-transform: uppercase;	color:#372b00;background:#ffd200; text-decoration:none;box-shadow:none; backface-visibility: hidden; -moz-osx-font-smoothing: grayscale;    transition: all 0s linear;
    &:before, &:after {content:'';position: absolute;top:0;left: -16px;border-top: 28px solid transparent;border-bottom: 29px solid transparent;border-right:16px solid #ffd200; }
    &:after {left:auto;right: -16px;transform:rotate(180deg);}
    &:hover {background: #e63a7b;  color: #fff;
		&:before, &:after {border-right:16px solid #e63a7b; }  
    }  
    &:active, &:focus {  opacity: 0.8; }  
}

.t-inp, textarea{ padding: 8px 0px 4px 0px; text-overflow: ellipsis;overflow: hidden;display:block;width:100%;color:#fff;
height:35px; border:none;border-bottom: 1px solid #fff; font-size:16px;font-weight:300; line-height:20px;background:transparent;}
input::-webkit-input-placeholder {    color:#fff;}
input:-moz-placeholder {    color:#fff;}
input::-moz-placeholder {    color: #fff;}
input:-ms-input-placeholder {   color:#fff;}

/* */
.section-home {position: relative;
	.wrapper {position: relative;height: 100%;z-index: 6;    width: 100%;}  
}

.lay {position: absolute;    top: 47px;    right: 23px;}
.lay1 {z-index: 3; width: 1054px;height: 700px; background: url(../img/lay1.png) no-repeat center;}
.lay2 {z-index: 4; width: 1054px;height: 700px; background: url(../img/lay2.png) no-repeat center;}
.bg-txt1 {position: absolute;font-weight: 800;    width: 38%;width:608px;    margin-top: -250px;    line-height: 1;right: 50%;text-align:center;top:50%;font-size: 600px;color: #f8f8f8;font-family:'ProximaNova';text-transform: uppercase;z-index: 0;}
.bg-txt2 {position: absolute;font-weight: 900;font-size: 200px;left:50%;bottom:17.2%;transform: rotate(-90deg);transform-origin: left bottom;    line-height: 200px;    margin-left: 161px; color: #e63a7b;font-family:'ProximaNova';text-transform: uppercase;z-index: 1;
	span {color: #fff;}
}
.home {   
	.bg-txt2 {opacity: 0; transition: all 0.4s ease-out;margin-bottom: 100px;}
	.slide__img {opacity: 0; transition: all 0.4s ease-out; top: -100px; transition-delay: 0.3s;}
	.home-girls-nav {opacity: 0; transition: all 0.4s ease-out;margin-right: 100px; transition-delay: 0.5s;}
}
.home:not(.loaded) {   
	.bg-txt2 {opacity: 1; margin-bottom: 0px;}
	.slide__img {opacity: 1; top: 0px;}
	.home-girls-nav {opacity: 1; margin-right: 0px;}
}

.hex {    position: relative;  overflow: hidden;    visibility: hidden; backface-visibility: hidden; transform: rotate(-60deg) skewY(30deg)  translate3d(0,0,0);
	&:before {content:'';position: relative;display: block; padding-bottom:113.89%;}
	* {     top: 0;    left: 0;   position: absolute;    visibility: visible;}  
	> div {    width: 100%;    height: 100%;    text-align: center;    overflow: hidden;    transform: skewY(-30deg) rotate(60deg) translate3d(0,0,0);    -webkit-backface-visibility: hidden;}
	img {     backface-visibility: hidden;   left: -100%;    right: -100%;    width: auto;height: 100%;margin: 0 auto;}
	a {    width: 100%;    height: 100%;    top: 0; left: 0;}   
}

.home-girls {height: 100%;width: 54.8%;position: absolute; z-index: 4;top:0;right:5.2%;
	.slick-list, .slick-track, .home-girls__item {height: 100%;}
	.slick-arrow {    margin-top: -150px;}
	.slick-prev {left: -117px;}
	.slick-next {left: -44px;}
	.slick-dots {left: -343px;right: auto;text-align: left;bottom: 50%; margin-bottom: 108px;}
}  
.slide__img {position: relative;z-index: 2;    margin-top:-8%;}
.slide__info {width: 215px;    z-index: 10; color: #8d8c8c;position: absolute;right: 0;top: 50%;     margin-top: -55px;
	.sm {font-size: 18px;font-weight: bold; font-family: "ProximaNova";}
	.price {font-size: 60px;font-weight: bold; font-family: "ProximaNova";color: #636363;    line-height: 1;    margin-bottom: 20px;
		.rub:after {background:#636363;height: 5px;width: 19px;    left: 5px;    bottom: 24px;}
	}
	.btn {    padding: 0 28.5px;}  
}
.rub{position: relative;    
	&:after {content:'';position: absolute;}
}

.home-girls-nav {width:448px;right: 56.2%;position: absolute;top: 50%;     margin-top: -76px;z-index: 5;  }
.girls-nav__item {position: relative;  height: 310px;
	.hex {width: 75px;margin: 30px auto; cursor: pointer;}
	&.slick-current {
		.hex {transform: scale(1.4) rotate(-60deg) skewY(30deg);}
		.girls-nav__name { color: #e63a7b; }
		.girls-nav__info {display: block;}
	}
	&:hover .girls-nav__name {color: #e63a7b;}
}
.girls-nav__name {text-align: right;cursor: pointer;line-height: 20px;position: absolute;top:149px; right: 50%;margin-right: 11px; font-size: 16px;color: #626262;text-transform: uppercase;font-weight: 900;font-family:'ProximaNova'; transform: rotate(-90deg); transform-origin: right 0;}
.girls-nav__info {display: none;text-align: center;    padding-top: 16px;    font-weight: 400;
	p {font-size: 14px;color: #535353; font-family: "ProximaNova";    padding: 0;    margin-bottom: 7px;}
	p + p:before {content:'';width:7px;height:2px;background:#cccccc;display:block;margin:0 auto 7px;}
}
.mob-head-btn {display: none;}

.section-404 {}
.section-404-img {position: absolute;left:-21px; top: 50%;transform: translateY(-56%);z-index: 0;}    
.section-404-txt {width: 400px;float: right; font-size: 16px;    padding-bottom: 20px; position: relative;z-index: 3;
	p {line-height: 1.9; font-weight: 300;    padding: 13px 0 15px 0;} 
}   
.section-404-title {font-weight: 300;font-size: 48px;color: #2e2e2e; font-family: "ProximaNova";    line-height: 1;}
.wrap-404 {position: relative;margin: 0 auto;max-width: 1342px;
	&:after {content:'';display: block;clear: both;}     
}    

.side-cont {width: 51%; padding:0 0px 83px 140px;
	.scroll { height: 342px;overflow: hidden; }
} 
.page-img {position: absolute;left: 54%;top:0px;    width: 718px;text-align: center; z-index: 3;}

.section-sale {
	.bg-txt2 {     margin-left: 289px;   bottom: 15.5%;}
}
.sale {counter-reset: myCounter;}
.sale__item {color: #585858;position: relative; padding: 10px 0 10px 52px; line-height: 1.9;
	p { padding-bottom: 5px;}
	&:before {line-height: 1;    top: -6px;    left: -1px; position:absolute;z-index: 0;font-weight: bold;color: #d9d9d9;font-size: 90px; font-family: "ProximaNova";	 counter-increment: myCounter; content:'0' counter(myCounter);}
}
.sale__title {    text-indent: -3px;position: relative;font-size: 48px;color: #2e2e2e; font-family: "ProximaNova"; font-weight: 300;     line-height: 1.22;    margin: 0px 0 16px;
	&:after {height: 5px;background: #e63a5e; width: 64px;content:'';display: block;    margin-top: 21px;}
}
/* */
.section-girl {
	.bg-txt2 {font-size: 120px;bottom: auto;top: 7%;left: auto;text-align: right;right: 60.7%;    font-weight: 800;width: 100%;    transform-origin: right top;}
	.wrapper {z-index: inherit;}
}
.page-photos {position: absolute;text-align: center;     right: 50%;    margin-right: -775px;top:0px;height: 100%;    width: 1000px;text-align: center; z-index: 3;
	.slick-arrow {overflow: visible;
		&:before {border-color: #ffc000;}
		span {position: absolute; text-transform: lowercase; width: 157px;    top: 127px;    left: 92px;    line-height: 1;border-bottom: 2px dotted #cecece;transform:rotate(180deg); font-size: 30px;color: #2e2e2e;font-family: "ProximaNova"; font-weight: 300; }
	}
	.slick-next {right: 25px;    margin-top: 8px;}
	.slick-prev {right: 223px;    margin-top: -40px;}
	img {display: inline-block;}
	.slick-list, .slick-track, .home-girls__item {height: 100%;}
	.slick-slide-img {position: absolute;left: 0;right: 0;top: 50%;transform: translateY(-50%);}
}
.custom_paging {right: 83px;    margin-top: -68px; position: absolute;top: 50%;color: #a9a9a9;font-weight: 800; font-family: "ProximaNova";font-size: 36px;
	li {display: none;
		b {font-size: 90px;color: #888888;  text-align: center;    font-weight: 800;  width: 106px;    display: inline-block;    vertical-align: middle;    position: relative;
			&:after {width:37px;height: 5px;transform: rotate(-43deg);background: #a1a1a1; content:'';position: absolute;    bottom: 19px;    right: -12px;}
		}
		span {    display: inline-block;    margin-bottom: -105px;    vertical-align: middle;}
	}
	li.slick-active {display: block;}
}

.icon-zod {display: inline-block;vertical-align: middle; margin: 1px 0 0 20px;
	&:before {content:'';display: block;background: url(../img/zodiac.png) no-repeat;} 
}
.icon-zod1:before {width: 24px;height: 18px;background: url(../img/zod1.svg) no-repeat center; background-size: 100%;}
.icon-zod2:before {width: 18px;height: 24px;background: url(../img/zod2.svg) no-repeat center; background-size: 100%;}
.icon-zod3:before {width: 18px;height: 18px;background: url(../img/zod3.svg) no-repeat center; background-size: 100%;}
.icon-zod4:before {width: 22px;height: 18px;background: url(../img/zod4.svg) no-repeat center; background-size: 100%;}

.icon-zod5:before {width: 25px;height: 22px;background: url(../img/zod5.svg) no-repeat center; background-size: 100%;}
.icon-zod6:before {width: 27px;height: 21px;background: url(../img/zod6.svg) no-repeat center; background-size: 100%;}
.icon-zod7:before {width: 23px;height: 16px;background: url(../img/zod7.svg) no-repeat center; background-size: 100%;}
.icon-zod8:before {width: 29px;height: 21px;background: url(../img/zod8.svg) no-repeat center; background-size: 100%;}

.icon-zod9:before {width: 22px;height: 20px;background: url(../img/zod9.svg) no-repeat center; background-size: 100%;}
.icon-zod10:before {width: 31px;height: 25px;background: url(../img/zod10.svg) no-repeat center; background-size: 100%;}
.icon-zod11:before {width: 21px;height: 17px;background: url(../img/zod11.svg) no-repeat center; background-size: 100%;}
.icon-zod12:before {width: 18px;height: 24px;background: url(../img/zod12.svg) no-repeat center; background-size: 100%;}

.girl__left { position: relative;z-index: 5;   width: 41%;    padding-left: 15px;
	.hexs {text-align: center;    margin: 0px 10px 0 0px; width: 560px; float: right;
		.hex {width: 75px;vertical-align: top;display: inline-block;margin: 0 16px -4px;}
		.hex:nth-child(5) {margin-left: 125px;}
	}
}
.girl__desc {    line-height: 1.9;     padding-right: 20px;   height: 203px;    margin-top: 9px;}
.girl__likes {float: left;width: 80px;text-align: center;height: 110px;    margin-right: 62px;
	b {display: block;font-size: 36px;color: #1f1f1f;    line-height: 49px;font-weight: 600;    margin-bottom: 13px;
		&:after {background:#cccccc;width: 33px;height: 2px;content:'';display: block;margin: 0 auto;}
	}
}
.girl__info {    margin: 0 auto 50px;    max-width: 388px; font-family: "ProximaNova";
	&:after {content:'';display: block;clear: both;}
}
.girl__age {font-size: 48px;color: #2e2e2e;font-weight: 300;    overflow: hidden;    line-height: 57px;    margin-bottom: 7px;}
.girl__params {}
.girl__params__item {font-weight: 300;color: #848484; display: inline-block;vertical-align: top;    margin-right: 16px;
	b {font-weight: 600;color: #2e2e2e;}
}

.fav {
	&:hover, &.active {
		.icon-heart:before {background-image:url(../img/heart2.svg);}
	}
}
.icon-heart {display: inline-block; vertical-align: middle;
	&:before {display:block;background: url(../img/heart.svg) no-repeat center;background-size: 100%; width: 45px;height: 36px;content:'';}
}

.service {counter-reset: myCounter;    max-width: 431px;}
.service__item {position: relative; padding: 10px 0 10px 49px; margin:0 0 71px;cursor: pointer;
	&:before {line-height: 1;    top: -3px;    left: -2px; position:absolute;z-index: 0;font-weight: bold;color: #d9d9d9;font-size: 60px; font-family: "ProximaNova";	 counter-increment: myCounter; content:'0' counter(myCounter);}
	&:after {content:'';display: block;clear: both;}
	&:hover .service__title {font-weight: bold;}
	&.active .service__title {color: #e63a7b;}
}
.service__left {float: left;width: 100%;padding-right: 85px; position: relative;z-index: 2;}
.service__price {width: 85px;float: left;margin-left: -85px;font-weight: bold;    padding-top: 3px;color: #2e2e2e;text-align: right; font-family: "ProximaNova";
	.rub {font-weight: 300;color: #636363;font-size: 24px;margin-left: 3px;display: inline-block;vertical-align: middle;
		&:after {height: 1px;width: 7px; background: #636363;bottom: 13px;left: 3px;}
	}
}
.service__title {color: #2e2e2e;font-size: 20px;font-weight: 300; font-family: "ProximaNova";line-height: 36px;    margin-bottom: 6px;}
.service__sm {color: #909090;font-size: 14px;font-weight: 300; font-family: "ProximaNova";}
.section-price {
	.scroll {    height: 420px;}
	.side-cont {    padding: 0 0px 6px 9.15%;    width: 43%;float: left;}
	.bg-txt2 {    transition: all 0.3s linear; transition-delay: 0.2s; bottom: auto;top: 7.6%;left: auto;text-align: right;right:50%;    margin-right: 40px; width: 100%;    transform-origin: right top;}
	.page-img {     transition: all 0.3s linear; left:47%; top: 50%;   transform: translateY(-39.5%);}
	&.open {
		.bg-txt2 { top: -100%; opacity: 0;}
		.page-img {top: 150%; opacity: 0;}
		.service-right {margin-right: 0px; opacity: 1;}
	}
}
.service-right {opacity: 0; float: right;margin-bottom: 6px;    width: 50.4%; margin-right: -800px;   transition: all 0.4s ease-out; transition-delay: 0.3s;position: relative;z-index: 10;
	&:before {content:''; top: 15px;  position: absolute; left: 5px;    bottom: 15px; border-left: 1px solid #cccccc;}
	.mCSB_inside > .mCSB_container {    margin-left: 0px;}
}
h2 {font-size: 48px; font-weight: 300; font-family: "ProximaNova"; text-align: left;text-transform: none;}
.panes {
	h2 {line-height: 1;      margin-left: -3px; margin-right: -32px;  padding: 35px 0 30px;    min-height: 121px;}
}
.pane {overflow: hidden;height: 0;}
.pane.active {overflow: visible;height: auto;}
.pane-in {margin: 0 auto;    max-width: 483px;}
.service__top {margin: 0 -3px 23px;
	&:after {content:'';display: block;clear: both;}
	.service__sm {float: right;font-weight: 400;color: #585858;line-height: 30px;
		&:before {    margin: -3px 12px 0 0;width: 22px;height: 23px;background: url(../img/ico2.png) no-repeat center;background-size: 100%;opacity: 0.3;content:'';display: inline-block;vertical-align: middle;}
	}
	.service__price {float: left;font-size: 24px;width: auto;text-align: left;margin: 0;padding: 0;line-height: 30px;
		&:before {    margin: -7px 13px 0 0;width: 22px;height: 19px;background: url(../img/ico1.png) no-repeat center;background-size: 100%;opacity: 0.3;content:'';display: inline-block;vertical-align: middle;}
	}
}
.service__desc {line-height: 30px;
	p {    padding: 10px 0;}
}
.price-tab {overflow: hidden;
	&:after {content:'';display: block;clear: both;}
}
.service__drop {display: none;}

/* */
.page-slider {
	header, footer {display: none;}
	.main-wrapper {display: block;}
	.visual-links {border-color: transparent;
		&:before {background:transparent;}
	}
	.visual-links__photo, .visual-links__video {color: #a0a0a0;top: -28px;
		&.active {font-size: 24px;}
	}
	.visual-links__video {    top: auto;    bottom: -27px;}
}
.section-girl {display: block;}
.girl-slider {height: 100%;width: 100%;
	.slick-list, .slick-track {height: 100%;}
	.slick-arrow {overflow: visible;
		&:before {border-color: #ffc000;}
		span {position: absolute; text-transform: lowercase; width: 157px;    top: 127px;    left: 92px;    line-height: 1;border-bottom: 2px dotted #cecece;transform:rotate(180deg); font-size: 30px;color: #2e2e2e;font-family: "ProximaNova"; font-weight: 300; }
	}
	.slick-next {right: 25px;    margin-top: 8px;}
	.slick-prev {right: 223px;    margin-top: -40px;}
	.page-photos {opacity: 0; margin-top: 100px;transition: all 0.4s ease-out; transition-delay: 0.3s;}
	.bg-txt2 {opacity: 0;margin-top: -100px;transition: all 0.4s ease-out;}
	.slick-active {
		.page-photos {opacity: 1;margin-top: 0px;}
		.bg-txt2 {opacity: 1;margin-top: 0px;}
	}	
}

/*-----------------------------------------------------------------------------*/

@media screen and (min-width: 1600px) {
	.girl-slider .slick-next {right: 50%;     margin-right: -775px;}
	.girl-slider .slick-prev {right: 50%;    margin-right: -577px; }
	.custom_paging {right: 50%;margin-right: -717px; }
}
@media screen and (max-width: 1599px) {
	.page-photos {    width: 62.5%;    right: 0;    margin-right: -0px;}
}
@media screen and (min-width: 1368px) {
	.girls-nav__item.slick-current .girls-nav__name {text-align: center; transform: rotate(0);position: static;margin-right: 0px;}
}
@media screen and (max-width: 1367px) {
	.bg-txt1 {    width: 623px;    margin-top: -268px;}
	.bg-txt2 {      font-size: 160px;      bottom: 12.6%;margin-left: 145px;}
	.home-girls {    width: 59.5%;    right: 2.1%;
		.slick-arrow {    margin-top: -168px;   }
		.slick-prev {left: -94px;}
		.slick-next {left: -22px;}
		.slick-dots {left: -237px;    margin-bottom: 126px;}
	}
	.slide__img {    margin-top: -5.5%;}
	.home-girls-nav {width: 282px;margin-top: -94px; right: 58.55%;}  
	.girls-nav__item.slick-current {
		.hex {transform: scale(1) rotate(-60deg) skewY(30deg);}
		.girls-nav__info {display: none;}
	}
	.slide__info {display: none;}
	.lay {    right: 58px;}
	.slick-dots li {    margin: 0 15px 0 0px;}
	.section-404-txt {    width: 397px;    padding-bottom: 13px;}
	.section-404-img {    left: 46px;    transform: translateY(-55.5%);}
	.side-cont {    width: 57%;    padding: 0 0px 83px 65px;}
	.mCSB_inside > .mCSB_container {    margin-left: 56px;}
	.section-sale .bg-txt2 {    margin-left: 288px;    bottom: 15.6%;    font-size: 200px;}  
	.page-img {    left: 56%;    width: 59.9%;}
	.girl__info {    max-width: 399px;}
	.girl__left {    width: 50%;    padding-left: 6px;
		.hexs {    margin: 0px -5px 0 0px;}
	}
	.girl__likes {    margin-right: 31px;}
	.girl__desc {    height: 229px;    margin-top: -9px;    padding-right: 75px;}
	.section-girl .bg-txt2 {    top: 7.7%;    right: 56%;}
	.page-photos {width: 59.5%;    right: -107px;
			
	}
	.girl-slider {
		.slick-prev {    right: 216px;    margin-top: -35px;}
		.slick-next {    right: 18px;    margin-top: 13px;
			span {border-color: #3e3e3e;}
		}	
	}
	.custom_paging {    margin-top: -63px;    right: 77px;color: #3e3e3e;
		li b {color: #3e3e3e;
			&:after {background:#3e3e3e;}
		}
	}
	.section-price {
		.side-cont {    padding:3px 0px 0px 63px;    width: 46%;
			.mCSB_inside > .mCSB_container {    margin-left: 39px;}
		}
		.bg-txt2 {     font-size: 200px;    top: 8.4%;     margin-right: 12px;}
		.page-img {    left: 48%;    transform: translateY(-32.5%);}
	}
	.service {  max-width: 425px;}
	.service-right {margin-bottom: 0;margin-top: 3px;    width: 50.7%; padding-right: 2.3%;}
	.pane-in {    max-width: 453px;}
	.panes h2 {padding: 4px 0 30px;  margin-right: -10px;  min-height: 91px;}
	

}

@media screen and (max-width: 1199px) {
	.bg-txt1 {    width: 477px;}
	.home-girls-nav {    width: 312px;	margin-top: -98px;    right: 58.6%;}
	.girls-nav__item .hex {    width: 83px;}
	.home-girls {    width: 69.8%;    right: -6%;
		.slick-prev {left: -76px;}
		.slick-next {left: -4px;}
		.slick-dots {left: -249px; }
	}
	.girls-nav__name {    top: 153px;}
	.lay {    right: -78px;    top: 43px;}
	.wrap-404 {    max-width: 846px;}
	.section-404-txt {    width: 330px;    padding-bottom: 0px;    padding-top: 18px;}
	.section-404-img {    left: -108px;}
	.side-cont {    width: 59.5%;    padding: 0 0px 75px 87px;}
	.sale__item {padding-left: 0;
		&:before {display:none;}
	}
	.section-sale .bg-txt2 {    margin-left: 286px;    bottom: 8.6%;}
	.mCSB_inside > .mCSB_container {    margin-left: 61px;}
	.page-img {     top: 17%;   left: 60%;    width: 45%;}
	.section-girl .bg-txt2 {    right: 57%;}
	.page-photos {    right: -95px;
		.slick-slide-img {    transform: translateY(-47%);}
	}
	.girl__info {    padding-left: 38px;}
	.girl__desc {    padding-right: 60px;    padding-left: 13px;}
	.section-price {
		.scroll {    height: 468px;}
		.page-img {     left: 55.2%;   width: 43.7%;transform: translateY(-38%);}
		.side-cont {    width: 43%;    padding: 3px 0px 0px 88px;}
	}
	.service__item {    padding: 10px 0 10px 22px;    margin: 0 0 23px;
		&:before {display: none;}
	}
	.service__left {padding-right: 0;float: none;}
	.service__price {float: none;text-align: left;width: auto;margin: 0;    padding-top: 18px;}
	.service {    max-width: 380px;}
	.service-right{     width: 55.2%; padding-right: 0%;
		&:before {display: none;}
		.mCSB_inside > .mCSB_container {    margin-left: 35px;}
	}
	.pane-in {    max-width: 432px;    margin: 0;}
	.panes h2 { padding: 6px 0 30px;    min-height: 93px;}
	.girl-slider {
		.slick-prev {    right: 228px;}
		.slick-next {    right: 30px;}	
	}
	.custom_paging { right: 89px;}

}
@media screen and (max-width: 1023px) {
	.bg-txt1 {    width: 50%;}
	.home-girls-nav {width: 297px;margin-top: -93px;    right: 55.1%;}
	.girls-nav__item .hex {    width: 79px;}
	.home-girls {width: 80%;    right: -12%;
		.slick-prev {left: -32px;}
		.slick-next {left: 40px;}
		.slick-dots {left: -191px;}
	}
	.slide__img {    margin-top:7.8%;}
	.section-404-txt {    width: 100%;    padding-bottom: 0px;    padding-top: 0px;    max-width: 395px;    margin: 0 auto;    float: none;    text-align: center;}
	.section-404-img {    transform: translateY(-57.2%);   left: -30px;    right: -44px;}
	.section-404-title br {display: none;}   
	.side-cont {      max-width: 500px;  width: 63%;    padding: 0 0px 27px 0px;    margin-left: -11px;   
		.scroll {height: 487px;}
	}
	.mCSB_inside > .mCSB_container {    margin-left: 37px;}
	.mCSB_scrollTools {    top: 0px;    bottom: 10px;}
	.section-sale .bg-txt2 {    bottom: 7.6%;}
	.page-img {    top: 17%;    left: 64%;    width: 453px;}
	.sale__title {    line-height: 1;    margin: 0px 0 15px;
		&:after {    margin-top: 27px;}
	}
	.sale__item {    padding: 0px 0 10px 0px;}     
	
	.custom_paging {display: none;visibility: hidden;right: -200px;opacity: 0;width: 0;}
	.girl__likes {float: right;     margin: 0px 0 9px;    width: 100px;} 
	.girl__age {clear: both;}
	.section-girl {
		.bg-txt2 {    right: 59.7%;    top: 5.3%;}
		.wrapper {padding: 0 16px;}
	}
	.girl-slider {
		.slick-arrow:before {border-color: #3b3b3b;}
		.slick-prev {    margin-top: -271px;    position: fixed;    left: 7px;    right: auto;    top: 50%;}
		.slick-next {      margin-top: -271px;    position: fixed;    left: 87px;    right: auto;    top: 50%;
			span {display: none;}
		}
	}
	.page-photos {    right: -30.7%;    width: 80%;
		.slick-slide-img {transform: translateY(-35.5%);}
	}
	.girl__left {    width: 41.3%;    padding-left: 0px;    margin-top: -81px;
		&:after {content:'';display: block;clear:both;}
		.hexs {     width: 310px;
			.hex {width: 71px;    margin:0 14.5px -3px;}  
			.hex:nth-child(4) {   margin:0 14.5px -3px 50px;}
			.hex:nth-child(5) {   margin:0 50px -3px 14.5px;}
		} 
	}
	.girl__info {    margin: 0 auto 65px;    padding-left: 0px;}
	.section-price {
		.scroll {    height: 440px;}
		.side-cont {    padding: 0px 0px 60px 1px;    width: 44%;
			.mCSB_inside > .mCSB_container {    margin-left: 35px;}
		}
		.mCSB_scrollTools {    top: 10px;    bottom: 10px;}
		.bg-txt2 {    margin-right: 39px;}
		.page-img {    left: 53.2%;    width: 59.5%;    transform: translateY(-38.5%);}
		.wrapper {padding-right: 10px;}
	}
	.service__item {padding: 0px 0 0px 0;    margin: 0 0 43px;}
	.service-right {margin-top: 0; width: 53.8%;}
	.service__top {    margin: 0 0px 23px;}
	.panes h2 { margin-right: 0px;    padding: 0px 0 29px;    margin-top: -2px;}
	.page-slider .visual-links {display: block;}


}

@media screen and (max-width: 1023px) and (min-width: 750px) {
	.girl__desc {display: none;}
}
@media screen and (max-width: 1023px) and (max-height: 739px) {
	.girl__left {margin-top: -31px;}
	.girl__likes {    margin: 0px 0 -9px;}
	.girl__info {    margin: 0 auto 25px;}
	.girl-slider .slick-prev, .girl-slider .slick-next {    margin-top: -217px;}
}

@media screen and (min-width: 750px) and (max-height: 729px) {
	.section-sale .side-cont .scroll {    height: 342px;}
}
@media screen and (max-width: 749px) {
	.wrapper {    padding: 0 10px;}
	.bg-txt1, .bg-txt2 {display: none;} 
	.section-home .wrapper {max-width: 320px;}
	.mob-head-btn {top:147px; position: absolute;left: 50%;width: 160px;margin-left: -80px;z-index: 30; display: block;}  
	.home-girls {width: 202%; right: -51%;
		.slick-arrow {display: none; opacity: 0;visibility: hidden;}
		.slick-dots {text-align: center;left: 0;right: 0;    margin-bottom: 0;    bottom: 253px;
			li {margin: 0 7px 0 7px;}
			li:not(.slick-active) button {background-position: 0 -14px;}
		}
	}
	.girls-nav__name {    transform: rotate(0deg); text-align: center;font-size: 14px;color: #e63a7b;    position: static;font-weight: 400;    margin-right: 0;}
	.girls-nav__item {height: 150px;
		.hex { margin:10px auto 15px auto;}   
	}
	.home-girls-nav {right:50%;margin-top: 0;bottom:85px;margin-right: -148px;top:auto;}
	.slide__img {     margin-left: -3%;   margin-top:-7.3%;}
	.page-img {display: none;}
	.side-cont {width: 100%;margin: 0; max-width: 100%;    
		.scroll {height: auto; max-height: 9999px;}
	}
	.long {
		.main-wrapper {overflow: hidden; height: auto;    min-height: 100%;}
		.content {height:auto;
			section {display: block;    height: auto;}
			.flex {display: block;    height: auto;}
		}
	}
	.mCSB_inside > .mCSB_container {    margin-left: 0px;}
	.page-photos { padding-left: 40px;overflow: hidden; position: relative;right: 0%;    width: 100%;   height: 615px;    margin: 0px auto 117px;
		.slick-slide-img {position: relative;margin: 0 auto; min-width: 395px;     max-width: 420px; top:0;   transform: translateY(0%);}
		&:after {content:'';position: absolute;left:95px; left:0; bottom: 0;right: 0;z-index: 20; height: 240px;background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(235,235,235,1) 100%);background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(235,235,235,1) 100%);background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(235,235,235,1) 100%);}
	}
	.girl-slider {height: auto; margin-top:-51px;
		.slick-arrow:before {width: 22px;height: 22px;border-width: 8px;}
		.slick-prev {position: absolute;    margin-top: 0;    top: 67px;    right: 0%;    margin-right: 21px;    left: auto;}
		.slick-next {position: absolute;    margin-top: 0;    top: 67px;    right: 0%;    margin-right: -10px;    left: auto;}
		.slick-slide {height: auto;display: block;}
		.slick-list {overflow: visible;}
	}
	.girl__left { width: 100%; display: -ms-flexbox;    display: -webkit-flex;    display: flex;    -webkit-flex-direction: column;    -ms-flex-direction: column;    flex-direction: column;    -webkit-flex-wrap: nowrap;    -ms-flex-wrap: nowrap;    flex-wrap: nowrap;    -webkit-justify-content: flex-start;    -ms-flex-pack: start;    justify-content: flex-start;    -webkit-align-content: stretch;    -ms-flex-line-pack: stretch;    align-content: stretch;    -webkit-align-items: flex-start;    -ms-flex-align: start;    align-items: flex-start;
		>div { -webkit-order: 0;    -ms-flex-order: 0;    order: 0;    -webkit-flex: 0 1 auto;    -ms-flex: 0 1 auto;    flex: 0 1 auto;    -webkit-align-self: auto;    -ms-flex-item-align: auto;    align-self: auto;}
		.hexs {    margin: 0 auto 45px;}
		> .girl__desc {-webkit-order: 3;    -ms-flex-order: 3;    order: 3;}
	}
	.section-girl {
		.wrapper {    padding: 0 5px;}
		.bg-txt2 {display: block; width: auto;   right: 100%;    margin-right: 43px;    top: 0%;    margin-top: -4px;}
	}
	.girl__info {text-align: center; width: 100%; max-width: 100%;   position: relative;    margin: 0 auto 39px;}
	.girl__desc {text-align: center; padding: 0 5px 38px;    height: auto;}
	.girl__likes {position: absolute;right: -5px;    top: -145px;    width: 71px;}
	.icon-zod {    margin: 1px -8px 0 20px;}
	.girl__params__item {    margin: 0 6px;}
	.section-price {
		.scroll {height: auto;}
		.side-cont {width: 100%;    padding: 37px 130px 0px 0;	    transition: all 0.3s linear;		
			.mCSB_inside > .mCSB_container {    margin-left: 0px;}
		}
		.bg-txt2 {display: block;right: 0;margin-right: 131px;    top: -102px;    margin-left: 0;    width: auto;}
		&.open {
			.side-cont {padding-right: 0;}
			.bg-txt2 { transition-delay: 0s;   margin-right: -15px; top: -102px;}
			.service {max-width: 100%;}
		}
	}
	.service__item {    margin: 0 0 44px;}
	.service-right {display: none;}
	.service__item.active {
		.service__title {    font-weight: bold;    color: #2e2e2e;}
		.service__drop {display: block; clear: both;    margin: 0px 0 -10px;}
		.service__sm {float: right; padding-top: 4px;}
		.service__price {float: left;    padding-top: 0px;    margin-bottom: -4px;    margin-top: -2px;}
	}
	.page-slider .visual-links {display: none;}
 

}
@media screen and (max-width: 639px) {
	.wrap-404 {max-width: 320px;}   
	.section-404 .wrapper {    padding: 0 15px;}
	.section-404-title {    font-size: 30px;    line-height: 36px;}
	.section-404-txt {    padding-top: 266px;
		p {    padding: 15px 0 15px 0;}
	}
	.section-404-img { transform: translateY(0%); top: -67px; left: -30px; right: -34px;}  
	.sale__title {font-size: 30px;    text-indent: -1px;    margin: 0px 0 2px;
		&:after {    margin-top: 20px;}
	}   
	.sale__item {    padding: 19px 0 19px 0px;}
	.side-cont {    padding: 26px 0 43px;}
	

}   
@media screen and (max-width: 499px) {
	.section-price .bg-txt2 {    margin-right: 129px;}
  
}

@media screen and (max-height: 599px) and (max-width: 749px) {
	.mob-head-btn {    top: 83px;}
	.home-girls-nav {    bottom: 30px;}
	.home-girls {width: 120%; right: -10%;
		.slick-dots {    bottom: 190px;}
	}
	.section-404-txt {max-width: 420px;padding: 42px 0 0;}

}
@media screen and (max-height: 479px) and (max-width: 749px){
	.home-girls-nav {display: none;}
	.home-girls {width: 120%; right: -10%;
		.slick-dots {    bottom: 45px;}
	}
}

/*--------------------------------------------------------------------------------------------------------------------------------*/
.section-contacts{position: relative;}
#map {      left: -31%; position: absolute;    top: 0;  z-index: 0;    width: 100%;   height: 100%;}
.contact__block{position: absolute; top:0; right:0%;      width: 50%; height: 100%;
	&:before{content:''; background: #fff; transform:rotate(-59.7deg); transform-origin: left; height:2000px;left:485px;width:2000px;position: absolute;top:695px;z-index: 1; }
}
.contact__girl {width: 538px; height:1087px; position:absolute; top: -77px;     left: -213px;z-index: 0;}
.contact{width: 344px; text-align:left;position: absolute; top:50%; margin-right: -586px;   right:50%;z-index: 3;transform: translateY(-59%);
	h2{font-family:'ProximaNova'; font-size:48px; color:#2e2e2e; position: relative; text-align:left;  font-weight: 300;      margin-bottom: 45px; text-transform: inherit;
		&:after{display:block;  content:''; width:64px; height:5px; background:#e63a5e; position: absolute; bottom:-12px; left:0;}
	}
	.address{font-size:18px;     line-height: 1.8;     margin-bottom: 22px;}
	.phone{font-size: 24px; color:#1c1c1c; line-height: 1;     font-weight: 400;     margin-bottom: 31px;
		a{text-decoration: none; color:#1c1c1c;}
	}
	.mail{font-size:16px;margin-bottom: 24px;
		a{ color:#f61680;display: inline-block;border-bottom: 1px solid #f61680;margin-bottom: -1px;
			&:hover{border-bottom: 1px solid transparent;}
		}
	}
	.soc{position: relative; right:auto; bottom:auto; width: 100%; 
		a{    font-size: 18px; margin-right: 31px;     margin-left: -3px;} 
	}
}
.gmnoprint, .gmnoprint{display:none;}
.section-madic-anonims{
	&:after{content:''; display:block; background:url(../img/img101.png) no-repeat 0 0; width:459px; height:712px; position: absolute; top:50%; left:50%; transform:translate(-50%, -46.6%);}
	&:before{content:''; width:165px; height:100%; background:url(../img/sh.png) repeat-y 0 0; position: absolute; top:0; right:50%;}
}
.cols__block{  
	&:after{display:block; clear:both; content:'';}
	.col{ width: 50%;float: left;         padding: 0px 100px 30px 147px;
		h2{font-family:'ProximaNova'; font-size:48px; color:#2e2e2e; position: relative; text-align:left;  font-weight: 300;        margin-bottom: 41px; text-transform: inherit;
			&:after{display:block;  content:''; width:64px; height:5px; background:#e63a5e; position: absolute; bottom:-11px; left:0;}
		}
		.col-text{font-size:16px;  color:#585858;     line-height: 1.9; text-align:left;}
	}
	.col2{      padding:0px 150px 0 116px;}
}

/* */
@media screen and (max-width: 1599px) {
	.contact {    margin-right: 0;    right: 10%;}
}
@media screen and (max-width: 1367px) {
	.contact__block{     width: 49.5%;}
	.contact__girl {left: -165px;    top: -69px;}
	.contact {    right: 3.6%;    transform: translateY(-58%);}
	.cols__block{    
		.col{     padding:38px 42px 0px 107px;
			h2{       margin-bottom: 43px;
				&:after{    bottom: -12px;}
			}
		}
		.col2{     padding: 38px 66px 0 53px;}
	}

}

@media screen and (max-width: 1199px) {
	.contact__block{ width:57.2%;
		&:before{       }
	}
	.contact__girl { left: -100px;    top: -75px;}
	.contact{    width: 215px;    right: 15%;    transform: translateY(-47.5%);
		.address{    margin-bottom: 44px;}
		.phone{display:none;}
	}
	.cols__block{   
		.col{padding:0px 12px 12px 103px;
			h2{  margin-bottom: 42px;}
		}
		.col2{    padding:0px 60px 0 66px;}
	}

}
@media screen and (max-width: 1023px) {
 	.contact__block{     width: 66.4%; 
		&:before{ }
	}  
	.contact__girl  { display:none;}
	.contact{      width: 215px;    right: 3%;    transform: translateY(-37.8%);
		.soc{    display: block;}
	}
	.section-madic-anonims .wrapper {    padding:0 17px 0 17px;}
	.cols__block{  
		.col{    padding:4px 15px 0px 0px;
			h2{    margin-bottom: 42px;}
		}
		.col2{       padding: 4px 0 0 30px;}
	}
}
@media screen and (max-width: 749px) {
	.contact__block{right: auto;  width: 208px; top:50%; position: absolute; transform:translateY(-8%);  margin: 0 auto;   left: auto;}
	.contact__block:before{display: none;}
	.contact{text-align:center;width: 200px;right: 50%;margin-right: -100px;bottom:7.9%;transform: translateY(0); top:auto;
		h2{text-align:center;
			&:after{    margin-left: -34px; left: 50%;}
		}
		.soc{
			a{    margin: 15px 14px 0px;}
		}
	}
	#map {    left: -35%;    width: 135%;    top: -25%;    height: 125%;}
	.section-madic-anonims{
		&:before{right:35px; height: auto;    bottom: -100px;   top: -96px;}
		&:after{left:auto; transform: translate(0%, 0%);     top: -8px;    right: -193px;}
		.wrapper{padding: 0 10px 0 10px;}
	}
	.cols__block{   
		.col{ width: 100%;  display: block; float:none;   padding:70px 55px 64px 0px;
			h2{    margin-bottom: 42px;}
		}
		.col2{            padding:0 57px 76px 0px;}
	} 
	

}

@media screen and (max-height: 749px) {
	.contact {    bottom: 40px;}
	#map {top: -40%;    height: 150%;}
}
@media screen and (max-height: 639px) {
	.contact {width: 310px;    margin-right: -155px;    bottom: 20px;
		.mail {margin: 0;}
		.address {    margin-bottom: 15px;}
	}
	#map {top: -50%;    height: 150%;}
}
@media screen and (max-height: 539px) {
	#map {top: -45%;    height: 145%;}  
	.contact h2 {margin: 0;
		&:after {    bottom: 6px;}
	}
}

@media screen and (max-height: 299px) {
	.contact {width: 526px;    margin-right: -258px;
		.mail {width: 50%;float: left;}
		.soc {width: 50%;float: left;
			a {    margin: -4px 14px 0px;}
		}
	}
}